a,
a:hover {
  color: inherit;
}

button {
  box-shadow: none !important;
  cursor: pointer;
  outline: none !important;
}

.book-container {
  // user-select: none;
  @media only screen and (max-width: 840px) {
    width: 100%;
  }
  width: 800px;
  margin: auto;
  font-family: Prompt;
}

.booktitle {
  font-family: Prompt;
  font-size: 26px;
  color: black;
  margin: 20px 0 32px 0;
  text-align: center;
  @media only screen and (max-width: 767px) {
    margin: 0px 0 16px 0;
    font-weight: 300;
    font-size: 20px;
  }
}

.followbtn,
.followingbtn {
  padding: 4px 20px 0 8px;
}

.followbtnTwoLine,
.followingbtnTwoLine {
  padding: 8px 20px 0 8px;
}

.followbtn,
.followingbtn,
.followbtnTwoLine,
.followingbtnTwoLine {
  transform: translateY(-1px);
  // height: 100%;
  width: 8px;
  font-size: 19px;
  margin-bottom: 0;
}

.followbtn:hover,
.followingbtn:hover,
.followbtnTwoLine:hover,
.followingbtnTwoLine:hover {
  cursor: pointer;
}

.trybtn {
  @media only screen and (max-width: 900px) {
    // width: 100%;
    font-size: 20px;
    // border-radius: 0px;
    // border-bottom: solid 1px #189aeb;
  }
  @media only screen and (max-width: 413px) {
    width: 100%;
  }
  background-color: white;
  width: 178px;
  height: 60px;
  border-radius: 100px;
  border: solid 1px #189aeb;
  color: #189aeb;
  font-family: Prompt;
  font-size: 22px;
}

.trybtn:active {
  transform: scale(0.95);
}

.pricebtn {
  @media only screen and (max-width: 900px) {
    // width: 100%;
    font-size: 20px;
    // border-radius: 0px;
  }
  @media only screen and (max-width: 413px) {
    width: 100%;
  }
  border: none !important;
  color: white !important;
  background-color: #189aeb !important;
  width: 183px;
  height: 62px;
  border-radius: 100px;
  font-family: Prompt;
  font-size: 22px;
}

.pricebtn-promotion {
  @media only screen and (max-width: 900px) {
    // width: 100%;
    font-size: 20px;
    // border-radius: 0px;
  }
  @media only screen and (max-width: 413px) {
    width: 100%;
  }
  border: none !important;
  color: white !important;
  background-color: red !important;
  width: 183px;
  height: 62px;
  border-radius: 100px;
  font-family: Prompt;
  font-size: 22px;
}

.pricebtn:hover {
  @media only screen and (max-width: 768px) {
    border: none !important;
    color: white !important;
    font-size: 20px;
    background-color: #189aeb !important;
  }
  border: solid 1px #189aeb !important;
  color: #189aeb !important;
  background-color: white !important;
  transition: all 0.3s ease-in;
}

.pricebtn-promotion:hover {
  @media only screen and (max-width: 768px) {
    border: none !important;
    color: white !important;
    font-size: 20px;
    background-color: red !important;
  }
  border: solid 1px red !important;
  color: red !important;
  background-color: white !important;
  transition: all 0.3s ease-in;
}

.clickpricebtn {
  @media only screen and (max-width: 900px) {
    // width: 100%;
    font-size: 20px;
    // border-radius: 0px;
  }
  @media only screen and (max-width: 413px) {
    width: 100%;
  }
  width: 183px;
  height: 62px;
  border-radius: 100px;
  background-color: #f2c94c !important;
  color: black !important;
  font-family: Prompt;
  font-size: 22px;
  border: none !important;
}

.purchasebtn {
  @media only screen and (max-width: 900px) {
    // width: 100%;
    font-size: 20px;
    // border-radius: 0px;
  }
  @media only screen and (max-width: 413px) {
    width: 100%;
  }
  width: 183px;
  height: 62px;
  border-radius: 100px;
  background-color: #bdbdbd !important;
  color: white !important;
  font-family: Prompt;
  font-size: 22px;
  border: none !important;
}

.purchasebtn:disabled {
  cursor: not-allowed;
}

.setbtn {
  color: black !important;
  padding: 0;
  border: none !important;
  background: none !important;
  outline: transparent !important;
  p {
    margin-top: 4px;
  }
}
.setbtn:active {
  transform: scale(0.95);
}

.shareBox,
.orderDetailBox {
  h3.BaiJamJuree14 {
    color: Black;
    font-family: Bai JamJuree;
    font-size: 18px;
  }

  .centerDiv {
    margin-left: 16px;
  }

  .title {
    font-family: Bai Jamjuree;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
  }

  .author,
  .price {
    font-family: Bai Jamjuree;
    font-size: 14px;
    color: #4f4f4f;
  }

  .title:hover,
  .author:hover {
    text-decoration: underline;
  }
}

.categorybtn {
  min-width: 102px;
  min-height: 36px;
  height: auto;
  font-size: 16px;
  color: white;
  border-radius: 4px;
}

.categorybtn:active {
  transform: scale(0.95);
}

// .followSubcat {
//   background-color: green;
// }
// .followingSubcat {
//   margin: auto;
//   background-color: red;
// }
// .catBtn {
//   background-color: black;
// }

.gradient {
  border: none;
  width: 100%;
  min-height: 36px;
  height: auto;
  // background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
  background-attachment: fixed;
}

.catName,
.subcatName,
.subcatNameAlone,
.tagBox {
  display: inline-flex;
  align-items: center;
  height: 36px;
  border-radius: 100px;
  background-color: #f1ebe6;
  color: black;
  margin-right: 8px;
}

.catName {
  padding: 0 6px 0 4px;
  .name {
    transform: translateY(2px);
    display: inline-flex;
    margin-left: 4px;
  }
}

.subcatName {
  padding: 0 0 0 6px;
  .name {
    height: 24px;
    transform: translateY(2px);
    display: inline-flex;
  }
}

.subcatNameAlone {
  padding: 0 0 0 4px;
  .name {
    transform: translateY(2px);
    display: inline-flex;
    margin-left: 4px;
  }
}

.tagBox {
  height: 30px !important;
  padding: 0 6px;
}

.subcatCircle {
  width: 16px;
  height: 16px;
  margin: 0px 5px 1px 0px;
}

.subcatCircle:hover {
  transform: scale(1.15);
}

.spanSubcatFollow {
  font-family: Prompt;
  font-size: 16px;
}

.purchaseChannel {
  font-family: Prompt;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 2px;
  color: #4f4f4f;
}

.tags {
  clear: both;
  display: block;
  line-height: 2.5;
}

.writeReview {
  margin-top: 5px;
  margin-bottom: 0px;
  font-family: Prompt;
  font-size: 20px;
  color: #000000;
}

.clickStar {
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: Prompt;
  font-size: 14px;
  color: #828282;
}

.submitReview {
  margin-top: 16px;
  margin-bottom: 43px;
  font-family: Prompt;
  font-size: 14px;
  color: white;
  width: 161px;
  height: 30px;
  border-radius: 2px;
  background-color: #189aeb;
  border: none !important;
}
.submitReview:active {
  transform: scale(0.95);
}

.hytextscoin {
  margin-right: 16px;
  transform: translateY(7px);
  height: 24px;
  padding: 0 0px 0 4px;
}

.authorAndPublisher {
  // height: 36px;
  padding: 0 0 0 4px;
  display: inline-flex;
  align-items: center;
  border-radius: 100px;
  background-color: #f1ebe6;
  margin-bottom: 0px;
  font-family: Prompt;
  font-size: 14px;
  color: black;
  .author,
  .publisher {
    display: inline-block;
    margin-left: 4px;
    vertical-align: middle;
  }
  .authorName,
  .publisherName {
    max-width: 225px;
    margin-left: 4px;
    margin-bottom: 0;
    display: inline-block;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    vertical-align: middle;
    @media only screen and (max-width: 840px) {
      max-width: 219px;
    }
    @media only screen and (max-width: 829px) {
      max-width: 209px;
    }
    @media only screen and (max-width: 808px) {
      max-width: 202px;
    }
    @media only screen and (max-width: 792px) {
      max-width: 193px;
    }
    @media only screen and (max-width: 776px) {
      max-width: 188px;
    }
    @media only screen and (max-width: 767px) {
      max-width: 239px;
    }
    @media only screen and (max-width: 440px) {
      max-width: 186px;
    }
    @media only screen and (max-width: 387px) {
      max-width: 137px;
    }
    @media only screen and (max-width: 338px) {
      max-width: 130px;
    }
  }
}

.hytextscoin {
  display: inline-flex;
  align-items: center;
  border-radius: 100px;
  background-color: #f1ebe6;
  margin-bottom: 0px;
  font-family: Prompt;
  font-size: 14px;
  color: black;
  .author,
  .publisher {
    display: inline-flex;
    margin-left: 4px;
  }
  .authorName,
  .publisherName {
    white-space: pre-wrap;
    display: inline-flex;
    margin-left: 4px;
  }
}

.synopsis {
  margin-bottom: 4px;
  font-family: Prompt;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.noteTitle{
  margin-bottom: 4px;
  font-family: Prompt;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}

.noteDetail{
  font-family: Bai Jamjuree;
  font-size: 10px;
  color: #4f4f4f;
  margin-bottom: 16px;
}

.dangerLabel{
  color: #a20006;
}

.synopsis-passage {
  font-family: Bai Jamjuree;
  font-size: 14px;
  color: #4f4f4f;
  margin-bottom: 16px;
}

.commentLabel {
  font-family: Prompt;
  font-size: 16px;
  color: #4f4f4f;
  margin-bottom: 0px;
}

.sortComments:hover {
  cursor: pointer;
}

.noCommentYet {
  @media only screen and (max-width: 692px) {
    width: 100%;
  }
  width: 692px;
  height: auto;
}

.eachComment {
  width: 100%;
  height: auto;
  border: 1px solid #cbdbd2;
  margin: 0px 0px 16px 0px;
  .commentatorName {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.purchased {
  color: #00a050;
  font-size: 12px;
  font-family: Prompt;
  margin-left: 5px;
}

.comment-Description {
  font-family: Bai Jamjuree;
  text-align: left;
  margin-left: 8px;
  margin-bottom: 11px;
}

.comment-BottomLabel {
  margin-left: 8px;
  font-family: Bai Jamjuree;
  font-size: 12px;
  color: #4f4f4f;
  margin-bottom: 11px;
}

.trashOfComment {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.trashOfComment:hover {
  cursor: pointer;
}

.ant-rate {
  color: #ffc000;
}

.avgRate,
.totalRate {
  transform: translateY(1px);
  font-size: 12px;
  display: inline-block;
}

.avgRate {
  margin: 0px 6px 12px 6px;
  color: #189aeb;
}

.commentsBtn-inactive,
.commentsBtn-active,
.commentsBtn-inactive-0,
.commentsBtn-active-0 {
  user-select: none;
  margin-left: 8px;
  margin-top: 3px;
  display: inline-block;
  text-align: center;
  @media only screen and (max-width: 430px) {
    width: 31%;
  }
  width: 122px;
  height: 40px;
  border-radius: 2px;
  font-size: 13px;
  .bottom {
    font-size: 11px;
  }
}

.commentsBtn-inactive-0:hover,
.commentsBtn-active-0:hover {
  cursor: not-allowed;
}

.commentsBtn-inactive:hover,
.commentsBtn-active:hover {
  cursor: pointer;
}

.commentsBtn-inactive,
.commentsBtn-inactive-0 {
  background-color: #f1ebe6;
  .bottom {
    color: #828282;
  }
}

.commentsBtn-active,
.commentsBtn-active-0 {
  border: solid 1px #189aeb;
  background-color: #ffffff;
  .bottom {
    color: #189aeb;
  }
}

.star-10px {
  font-size: 10px;
  .ant-rate-star:not(:last-child) {
    margin-right: 2px;
  }
  transform: translateY(-20px);
}

.star-14px {
  font-size: 14px;
  .ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }
}

.star-20px {
  font-size: 20px;
  .ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }
}

.ant-rate-star-second {
  color: #bdbdbd;
}

.coverImage-ebook {
  // img {
  //   border: 1px solid black;
  // }

  .tagList-twoTags {
    @media only screen and (max-width: 541px) {
      top: -7px !important;
    }
    @media only screen and (max-width: 460px) {
      top: -24px !important;
    }
  }

  .tagList,
  .tagList-twoTags {
    cursor: default;
    @media only screen and (max-width: 541px) {
      right: 14px;
      top: 4px;
      transform: scale(0.75);
    }
    @media only screen and (max-width: 460px) {
      right: 14px;
      top: -5px;
      transform: scale(0.55);
    }

    top: 14px;
    right: 20px;
    width: 60px;
    position: absolute;

    .tag {
      margin-top: -3px;
      text-align: center;
      position: relative;
      color: white;
      min-height: 80px;

      .tagTitle,
      .tagTitle-discount {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        line-height: 1.1;
        font-size: 20px;
        width: 60px;

        span {
          color: #ff0000;
          font-weight: bold;
        }
      }

      .red {
        color: #ff0000;
        margin-bottom: 4px;
      }

      .tagTitle-discount {
        transform: translateY(-66%);
      }
    }

    .offset {
      transform: translateY(-30px);
    }
  }
  .filetype {
    cursor: default;
    @media only screen and (max-width: 460px) {
      transform: scale(0.58);
      top: 9px;
      left: 9px;
    }
    width: 82px;
    height: 33px;
    font-size: 20px;
    background-color: #009f6fb2;
    color: white;
    text-align: center;
    font-weight: 1000;
    position: absolute;
    top: 16px;
    left: 27px;
  }

  .pdf {
    opacity: 0.95;
    background-color: #ff0000;
  }

  .epub {
    opacity: 0.95;
    background-color: #009f6e;
  }
}
