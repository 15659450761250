.alertReviewModal{
    .ant-modal-content{
        border-radius: 20px;
        overflow: hidden;
        background-color: unset;
    }
    .ant-modal-body{
        padding:0px !important; 
    }
    .review-cover--container{
        width: 100%;
        position: relative;
      
        img{
            object-fit: cover;
         
        }
    }
    .alert-review-content{
        text-align: center;
        padding:2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:8px;
        background-color: white;

        .submit-btn{
            width: 150px;
            font-family: Prompt;
        }
    }
    .alert-review-title{
        color: #1477B6;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Prompt;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }
    .danger-text{
        color:#FF0000;
        font-size: 18px;
        font-weight: 400;
    }
    .content-text{
        text-align: center;
        color: #1C1C1E;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Prompt;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        font-size: 18px;
    }
}


.ant-scrolling-effect{
    overflow: unset !important;
    width: unset !important;
    position: unset !important;
  }