header.navbar,
header.navbar-noshadow {
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  height: auto;
  padding: 0px;
  input.ant-input {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

header.navbar-noshadow {
  position: fixed;
  z-index: 100;
  width: 100%;
  min-width: 290px;
  left: 0;
  line-height: 40px;
  // @media only screen and (max-width: 900px) {
  //   bottom: 54px;
  // }
  // @media only screen and (max-width: 600px) {
  //   bottom: 52px;
  // }
  // @media only screen and (max-width: 450px) {
  //   bottom: 50px;
  // }
}

.navMenuSet,
.navMenuSet-footer {
  border-bottom: 0px !important;
  /* display: flex;
     justify-content: center;*/
  min-height: 44px;
  // max-width: 1440px;
  margin: auto;
  font-family: Prompt, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.navMenuSet {
  padding: 0px 36px;

}

a.menuLogo {
  height: 44px;
}

img.menuLogo {
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  height: 22px !important;
  width: 124px;
}

.search-bar.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}

.searchBar-top,
.searchBar-inlist {
  input.ant-input {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  font-family: Prompt;
  .searchview-result:hover {
    cursor: pointer;
    // background-color: rgba(0, 0, 0, 0.05);
  }
}

.divider-searchBox {
  color: rgba(0, 0, 0, 0.65);
  height: 32px !important;
  width: 1px;
  transform: translateX(440px);
  z-index: 2;
}

.divider-searchBox-nav {
  color: rgba(0, 0, 0, 0.65);
  height: 32px !important;
  width: 1px;
  transform: translateX(170px);
  z-index: 2;
}

.ant-dropdown {
  position: fixed !important;
  top: 60px !important;
}

.search-bar-dropdown {
  position: fixed !important;
  top: 60px !important;
}

.search-bar-inlist-dropdown {
  position: fixed !important;
  top: 200px !important;
}

.search-bar-dropdown .ant-select-dropdown-menu-item-group-title,
.search-bar-inlist-dropdown .ant-select-dropdown-menu-item-group-title {
  font-family: Prompt;
  font-size: 16px;
  color: #4f4f4f;
}

.search-bar-dropdown .ant-select-dropdown-menu-item-group,
.search-bar-inlist-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.search-bar-dropdown .ant-select-dropdown-menu-item,
.search-bar-inlist-dropdown .ant-select-dropdown-menu-item {
  font-family: Prompt;
  font-size: 12px;
  color: #4f4f4f;
  font-weight: 300;
  padding-left: 16px;
}

.search-bar-dropdown .ant-select-dropdown-menu-item.show-all,
.search-bar-inlist-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.search-bar-dropdown .ant-select-dropdown-menu,
.search-bar-inlist-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

.search-bar.ant-select-focused .search-bar-icon {
  color: #108ee9;
}

.search-bar-icon {
  background-color: white;
  padding-left: 4px;
  color: #6e6e6e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}

.navtabs {
}

.right-menu-item {
  float: right;
}

.ant-tabs {
  clear: both;
}

.ant-tabs-bar {
  margin-bottom: 5px !important;
}

.hytextscoin {
  display: inline-flex;
  align-items: center;
  a {
    margin-right: 0px;
    z-index: 2;
    transform: translateY(1px);
  }
  img.buycoin {
    transform: translateX(10px);
    width: 25px;
    height: 25px;
    z-index: 2;
  }

  span.remaincoin {
    padding: 0px 10px 0px 18px;
    transform: translateY(1px);
  }

  img.yellowcoin {
    width: 20px;
    height: 20px;
    z-index: 2;
  }

  p.remaincoin {
    display: flex;
    height: 23px;
    padding: 0px 35px;
    transform: translateY(8px);
    border: 1px solid #dbcbcb;
    span {
      transform: translateY(-21px);
    }
  }
}
.ant-badge-count {
  background: #d0021b;
}
.navBadge {
  transform: scale(0.8);
  width: 32px;
  top: -10px !important;
  left: -15px !important;
}

.profile-btn {
  margin-right: 16px;
  border-radius: 50%;
  border: 1px solid #bdbdbd !important;
  background-color: transparent;
  height: 43px;
  width: 43px;
  border: none;
  object-fit: cover;
}

.ant-dropdown-menu {
  padding: 5px 0 !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a,
.ant-dropdown-menu-item > button,
.ant-dropdown-menu-submenu-title > button {
  width: 100%;
  border: none;
  background-color: transparent;
  font-family: Prompt;
  font-size: 16px !important;
  color: black !important;
  img {
    width: 20px;
    height: 18px;
    transform: translateY(-2px);
    margin-right: 8px;
  }
}

.ant-dropdown-menu-submenu
  .ant-dropdown-menu-submenu-popup
  .ant-dropdown-menu-light
  .ant-dropdown-menu-submenu-placement-leftTop
  .ant-dropdown-menu-submenu-hidden {
  top: 120px !important;
}

.profile-btn:hover {
  cursor: pointer;
}

.profile-dropdown-creator {
  height: 360px !important;
}

.menuView {
  .ant-divider-horizontal {
    margin: 12px 0 !important;
  }
  .displayname {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    font-family: Prompt;
    text-overflow: ellipsis;
    font-size: 16px;
    color: black;
    margin-bottom: 8px;
  }
  .labeltop {
    margin-bottom: 0px;
    font-family: Prompt;
    font-size: 12px;
    color: #4f4f4f;
  }
  .labelunder {
    font-family: Bai Jamjuree;
    font-size: 12px;
    color: #4f4f4f;
  }
  a {
    font-family: Prompt;
  }
  .camera:hover {
    cursor: pointer;
  }
}

.profile-dropdown,
.profile-dropdown-creator {
  input.ant-input {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  position: absolute;
  width: 220px;
  height: 237px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  top: 0px;
  z-index: 5;
  button {
    border: none;
    background-color: transparent;
  }

  .profile {
    padding: 5px 12px;
    height: 90px;
    .displayname {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      font-family: Prompt;
      text-overflow: ellipsis;
      font-size: 16px;
      color: black;
      margin-bottom: 4px;
    }
    .labeltop {
      margin-bottom: 0px;
      font-family: Prompt;
      font-size: 12px;
      color: #4f4f4f;
    }
    .labelunder {
      font-family: Bai Jamjuree;
      font-size: 10px;
      color: #4f4f4f;
    }
    .camera:hover {
      cursor: pointer;
    }
  }
  .ant-divider-horizontal {
    margin: 0px auto;
    transform: translateY(-9px);
    background-color: #bdbdbd;
    display: block;
    clear: both;
    width: 204px;
    min-width: 0px;
    height: 1px;
  }

  img.yellowcoinDropdown {
    width: 18px;
    height: 18px;
    z-index: 2;
  }
}

.login-btn {
  margin-right: 16px;
  border-radius: 2px;
  width: 105px;
  background-color: #f2c94c;
  color: black;
  padding: 4px 4px;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-size: 14px;
  border: 1px solid white;
  transition: all 0.3s ease-in;

  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
    transition: all 0.3s ease-in;
  }
}

.login-writer-btn {
  margin-right: 16px;
  border-radius: 2px;
  width: 105px;
  background-color: white;
  color: black;
  padding: 4px 4px;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-size: 14px;
  border: 1px solid white;
  transition: all 0.3s ease-in;
  border: 1px solid #f2c94c;

  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
    transition: all 0.3s ease-in;
  }
}

.navbtn-set {
  margin-bottom: -8px;
}

.navbtn-set,
.navbtn-set-isMobile {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  div {
    display: inline-block;
  }
  text-align: center;
  .navbtn,
  .navbtn-active {
    font-family: Prompt;
    font-size: 16px;
    @media only screen and (max-width: 440px) {
      font-size: 15px;
    }
    border: transparent !important;
    background-color: transparent !important;
    box-shadow: transparent !important;
    outline: transparent !important;
  }

  .navbtn:hover,
  .navbtn-active:hover {
    cursor: pointer;
  }

  .navbtn-footer,
  .navbtn-active-footer {
    padding-right: 16px;
    font-family: Prompt;
    font-size: 16px;
    border: transparent !important;
    background-color: transparent !important;
    box-shadow: transparent !important;
    outline: transparent !important;
    p {
      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
      @media only screen and (max-width: 600px) {
        font-size: 10px;
      }
      @media only screen and (max-width: 450px) {
        font-size: 8px;
      }
      line-height: 0px;
    }
    .numCart {
      transform: translate(-10px, 4px);
      line-height: 19px;
      position: absolute;
      height: 19px;
      width: 19px;
      background-color: #d0021b;
      border-radius: 50%;
      display: inline-block;
      color: white;
      font-size: 12px;
      text-align: center;
    }
    img {
      image-rendering: -webkit-optimize-contrast;
      width: 24px;
      height: 24px;
    }
  }

  .navbtn {
    color: black;
  }
  .navbtn-footer {
    color: #828282;
  }

  .navbtn-active,
  .navbtn-active-footer {
    color: #189aeb;
  }

  .scroll-menu-arrow {
    padding: 0px;
    cursor: pointer;
  }
}

.backtoRegister {
  color: rgba(0, 0, 0, 0.35);
  transform: translateX(-10px) translateY(-19px);
  font-size: 20px;
}

.backfromForgetPW {
  color: rgba(0, 0, 0, 0.35);
  font-size: 20px;
}

.backtoLogin {
  color: rgba(0, 0, 0, 0.35);
  transform: translateX(6px) translateY(-3px);
  font-size: 20px;
}

.backtoRegister:hover,
.backtoLogin:hover,
.backfromForgetPW:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.55);
}
