$footer-height: 144px;
$header-height: 106px;

html {
  // scroll-behavior: smooth;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  // display: none;
}

.cookieTab {
  padding-top: 4px;
  text-align: center;
  font-family: Prompt;
  color: black;
  @media only screen and (max-width: 420px) {
    font-size: 14px;
  }
  font-size: 16px;
  a {
    color: #189aeb;
    text-decoration: underline;
    margin-right: 30px;
    @media only screen and (max-width: 850px) {
      margin-right: 0;
    }
  }
  button {
    @media only screen and (max-width: 420px) {
      width: 68px;
      height: 28px;
      font-size: 14px;
    }
    @media only screen and (max-width: 820px) {
      margin-left: 12px;
    }
    @media only screen and (max-width: 340px) {
      margin-left: 8px;
    }
    border: none;
    width: 76px;
    height: 31px;
    border-radius: 100px;
    background-color: #189aeb;
    color: white;
    font-size: 16px;
    line-height: normal;
  }
}

.cookie-policy {
  color: black;
  .title {
    text-align: center;
    font-family: Prompt;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .phrase {
    line-height: 32px;
    font-family: Bai Jamjuree;
    .plain {
      font-size: 16px;
      margin-bottom: 24px;
      a {
        color: #189aeb;
        text-decoration: underline;
      }
    }
    .plain-no-margin-bottom {
      font-size: 16px;
      margin-bottom: 0px;
    }
    .bold {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 0 !important;
}

::-webkit-scrollbar,
#shoppingcart::-webkit-scrollbar {
  height: 0px;
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track,
#shoppingcart::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb,
#shoppingcart::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
#shoppingcart::-webkit-scrollbar-thumb:hover {
  background: #555;
}

header.navbar {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}

.footer-Row,
.contactUs {
  h5,
  div {
    font-size: 13px;
  }
}

.footer-link,
.faq-link {
  color: #189aeb;
}

.footer-link:hover {
  text-decoration: underline;
}

.gotoTop {
  z-index: 555;
  position: fixed;
  width: 48px;
  border-radius: 2px;
  background-color: #f2c94c;
  @media only screen and (max-width: 900px) {
    display: none;
  }
  bottom: 8px;
  right: 14px;
  font-family: Prompt;
  font-size: 18px;
  color: black;
  border: none;
}

.devWaterMark {
  z-index: 555;
  position: fixed;
  width: 120px;
  border-radius: 2px;
  background-color: #ff0099;
  top: 8px;
  left: 50%;
  font-family: Prompt;
  font-size: 18px;
  color: white;
  border: 5px solid #22ffaa;
}

.fadeout {
  clear: both;
  animation: deleteDiv 200ms ease-in 0s 1 both;
}

.fadein {
  clear: both;
  animation: addDiv 400ms ease-in 0s 1 both;
}

@keyframes deleteDiv {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes addDiv {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ant-tabs {
  font-family: Prompt, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.ant-modal-body {
  padding: 16px !important;
}

nav input.ant-input {
}

p.topiclist {
  text-align: left;
  @media only screen and (max-width: 1056px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 956px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 856px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 756px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 656px) {
    font-size: 18px;
  }
  margin-bottom: 0;
  font-family: Prompt;
  font-size: 32px;
  font-weight: normal;
  color: black;
  /* white-space: nowrap; */
  width: 100%;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

span.booknumber {
  font-family: Prompt;
  font-size: 18px;
  font-weight: normal;
}

p.liblink {
  font-family: Bai JamJuree;
  font-size: 14px;
  font-weight: normal;
  @media only screen and (max-width: 756px) {
    text-decoration: underline;
  }
}

p.liblink:hover {
  cursor: pointer;
  text-decoration: underline;
}

.ant-select-disabled .ant-select-selection {
  background: white !important;
}

div.homelist {
  @media only screen and (max-width: 1414px) {
    margin-left: 16px;
    margin-right: 16px;
  }
  @media only screen and (max-width: 1020px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media only screen and (max-width: 814px) {
    margin-bottom: 28px;
  }
  margin-bottom: 24px;

  .tag-list-searchview {
    // div:hover{
    //   cursor: pointer;
    //   background-color: rgba(0, 0, 0, 0.05);
    // }
    font-family: Prompt;
    clear: both;
    display: block;
    line-height: 2.5;
    color: #189aeb;
  }

  .tag-list {
    @media only screen and (max-width: 1020px) {
      margin-left: 16px;
    }
    font-family: Bai Jamjuree;
    clear: both;
    display: block;
    line-height: 2.5;
  }
  span.ant-radio + * {
    padding-right: 0px;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #189aeb !important;
    border: 1px solid #189aeb !important;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper:hover {
    color: #189aeb !important;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:hover {
    color: white !important;
  }
  .inactive:hover {
    color: #189aeb;
  }
}

h2.homelist {
  @media only screen and (max-width: 1020px) {
    margin-left: 16px;
  }
}

h2.homelist,
h2.homelist-related {
  margin-bottom: 4px;
  float: left;
}

h2.homelist,
h2.homelist-related,
h2.homelist-searchview {
  font-family: Prompt, sans-serif;
  font-size: 20px;
  @media only screen and (max-width: 814px) {
    font-size: 16px;
  }
  font-weight: normal;
}

.viewmore {
  @media only screen and (max-width: 1020px) {
    margin-right: 16px;
  }
}

.viewmore,
.viewmore-related {
  float: right;
  padding-right: 0 !important;
  text-align: right !important;
  color: #4f4f4f !important;
  font-size: 16px;
  @media only screen and (max-width: 814px) {
    font-size: 12px !important;
  }
  font-family: Prompt;
}

.content-container {
  @media only screen and (max-width: 900px) {
    padding-top: $header-height - 15px - 91px;
    padding-bottom: 51px;
  }
  padding-top: $header-height - 91px;
  margin: auto;
  min-height: calc(100vh - #{$footer-height}) !important;
  min-width: 300px;
}

.content-container-home {
  @media only screen and (max-width: 900px) {
    padding-top: $header-height - 15px;
    padding-bottom: 51px;
  }
  padding-top: $header-height;
  margin: auto;
  min-height: calc(100vh - #{$footer-height}) !important;
  min-width: 300px;
  @media only screen and (max-width: 500px) {
    .BrainhubCarousel__dots .BrainhubCarousel__dot {
      padding: 5px;
    }
  }
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  background-color: white;
}

.content-container-ebook {
  @media only screen and (max-width: 900px) {
    padding-top: 0px;
    padding-bottom: 51px;
  }
  padding-top: $header-height - 16px;
  margin: auto;
  min-height: calc(100vh - #{$footer-height}) !important;
  min-width: 300px;
}

.content-container-flash-sale {
  @media only screen and (max-width: 900px) {
    padding-top: $header-height + 46px - 12px - 91px;
    padding-bottom: 51px;
  }
  padding-top: $header-height + 46px;
  margin: auto;
  min-height: calc(100vh - #{$footer-height}) !important;
  min-width: 300px;
}

.content {
  padding: 24px;
  max-width: 1440px;
  margin: auto;
  position: relative;
}

.content-noSidePadding {
  margin-left: -16px;
  padding: 16px 0px 16px 0px;
  max-width: 1440px;
  margin: auto;
  position: relative;
}

@media only screen and (max-width: 320px) {
  .ant-steps {
    display: none !important;
  }
}

.ant-modal-confirm .ant-modal-confirm-btns button + button {
  background-color: #189aeb !important;
  border: 1px solid #189aeb !important;
}

div.ebook-view-home {
  width: 151px;
  height: 282px;
  @media only screen and (max-width: 420px) {
    width: 116px;
    height: 228px;
  }
  display: block;
  position: relative;
  font-family: Prompt, sans-serif;
  border: 1px solid #efefef;
  @media only screen and (max-width: 814px) {
    padding: 4px;
  }
  padding: 8px;
  margin-right: 8px;

  .cover-container {
    background: white;
    width: 100%;
    max-height: 279px;
    min-height: 182px;
    @media only screen and (max-width: 814px) {
      min-height: 191px;
    }
    @media only screen and (max-width: 420px) {
      min-height: 142px;
    }
    position: relative;
    overflow: hidden;
  }

  .title {
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
    // font-weight: 300;
    // margin-top: 0px;
    // margin-bottom: -3px;
    // font-size: 16px;
    // width: 100%;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    margin-top: 4px;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Prompt", sans-serif;
  }

  .title:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .author {
    @media only screen and (max-width: 576px) {
      font-size: 10px;
    }
    font-weight: 300;
    color: #4f4f4f;
    font-size: 12px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Bai Jamjuree", sans-serif;
  }

  .author:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .ant-rate {
    font-size: 12px;
  }

  button.buybtn {
    @media only screen and (max-width: 1100px) {
      font-size: 14px;
      height: 26px;
    }
    font-size: 16px;
    width: 100%;
    height: 30px;
    padding: 0px;
    border-radius: 2px;
    background-color: #189aeb !important;
    border: 1px solid #189aeb !important;
    color: white;
    .title {
      margin: auto;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
   
  }

  button.purchasebtn {
    @media only screen and (max-width: 1100px) {
      font-size: 14px;
      height: 26px;
    }
    font-size: 16px;
    width: 100%;
    height: 30px;
    padding: 0px;
    border-radius: 2px;
    background-color: #bdbdbd !important;
    border: 1px solid #bdbdbd !important;
    color: white;
  }

  button.incartbtn {
    @media only screen and (max-width: 1100px) {
      font-size: 12px;
      height: 26px;
    }
    background-color: #f2c94c !important;
    border: 1px solid white !important;
    width: 100%;
    height: 30px;
    padding: 0px;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button.flashsale-btn {
    background-color: #ff0000 !important;
    border: 1px solid #ff0000 !important;
    color: white;
    .title {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .discount {
    color: #bdbdbd;
    font-size: 12px;
    font-weight: 300;
    text-decoration: line-through;
    text-align: center;
    @media only screen and (max-width: 900px) {
      padding: 4px 0px;
      font-size: 10px;
    }
    padding: 6px 0px;
  }

  .rating {
    height: 20px;
    margin-bottom: 16px;
  }
}

div.ebook-view {
  display: block;
  position: relative;
  font-family: Prompt, sans-serif;
  border: 1px solid #efefef;
  padding: 8px;
  @media only screen and (max-width: 900px) {
    padding: 4px;
  }

  .cover-container {
    width: 100%;
    max-height: 285px;
    min-height: 282px;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: 1470px) {
      min-height: 280px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1420px) {
      min-height: 280px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1350px) {
      min-height: 260px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1274px) {
      min-height: 250px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1200px) {
      min-height: 230px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1104px) {
      min-height: 194px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1040px) {
      min-height: 185px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 970px) {
      min-height: 205px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 900px) {
      min-height: 230px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 840px) {
      min-height: 220px !important;
      height: 100%;
      width: auto;
    }
  }

  .cover-container-topic {
    width: 100%;
    max-height: 293px;
    min-height: 290px;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: 1470px) {
      min-height: 200px+88px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1420px) {
      min-height: 204px+60px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1350px) {
      min-height: 180px+72px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1274px) {
      min-height: 170px+66px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1200px) {
      min-height: 150px+66px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1104px) {
      min-height: 190px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 1040px) {
      min-height: 181px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 970px) {
      min-height: 171px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 900px) {
      min-height: 176px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 840px) {
      min-height: 162px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 768px) {
      min-height: 224px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 700px) {
      min-height: 206px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 670px) {
      min-height: 188px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 640px) {
      min-height: 178px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 610px) {
      min-height: 168px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 550px) {
      min-height: 146px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 500px) {
      min-height: 133px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 470px) {
      min-height: 126px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 440px) {
      min-height: 154px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 390px) {
      min-height: 143px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 375px) {
      min-height: 130px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 350px) {
      min-height: 116px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 320px) {
      min-height: 100px !important;
      height: 100%;
      width: auto;
    }
  }

  .cover-container-related {
    width: 100%;
    max-height: 240px-25px;
    min-height: 152px;
    @media only screen and (max-width: 440px) {
      max-height: 240px;
      min-height: 240px;
    }
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: 800px) {
      min-height: 140px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 767px) {
      min-height: 218px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 700px) {
      min-height: 210px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 670px) {
      min-height: 192px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 640px) {
      min-height: 182px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 610px) {
      min-height: 172px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 550px) {
      min-height: 150px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 500px) {
      min-height: 132px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 470px) {
      min-height: 125px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 440px) {
      min-height: 116px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 390px) {
      min-height: 147px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 375px) {
      min-height: 124px !important;
      height: 100%;
      width: auto;
    }

    @media only screen and (max-width: 320px) {
      min-height: 104px !important;
      height: 100%;
      width: auto;
    }
  }

  .title {
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
    // font-weight: 300;
    // margin-top: 0px;
    // margin-bottom: -3px;
    // font-size: 16px;
    // width: 100%;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    margin-top: 4px;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Prompt", sans-serif;
  }

  .title:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .author {
    @media only screen and (max-width: 576px) {
      font-size: 10px;
    }
    font-weight: 300;
    color: #4f4f4f;
    font-size: 12px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Bai Jamjuree", sans-serif;
  }

  .author:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .ant-rate {
    font-size: 12px;
  }

  button.buybtn {
    @media only screen and (max-width: 576px) {
      font-size: 14px;
      height: 26px;
    }
    font-size: 16px;
    width: 100%;
    height: 30px;
    padding: 0px;
    border-radius: 2px;
    background-color: #189aeb !important;
    border: 1px solid #189aeb !important;
    color: white;
    .title {
      margin: auto;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  button.purchasebtn {
    @media only screen and (max-width: 576px) {
      font-size: 14px;
      height: 26px;
    }
    font-size: 16px;
    width: 100%;
    height: 30px;
    padding: 0px;
    border-radius: 2px;
    background-color: #bdbdbd !important;
    border: 1px solid #bdbdbd !important;
    color: white;
  }

  button.incartbtn {
    @media only screen and (max-width: 576px) {
      font-size: 12px;
      height: 26px;
    }
    background-color: #f2c94c !important;
    border: 1px solid white !important;
    width: 100%;
    height: 30px;
    padding: 0px;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button.flashsale-btn {
    background-color: #ff0000 !important;
    border: 1px solid #ff0000 !important;
    color: white;
    .title {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .discount {
    color: #bdbdbd;
    font-size: 12px;
    font-weight: 300;
    text-decoration: line-through;
    text-align: center;
    @media only screen and (max-width: 900px) {
      padding: 4px 0px;
      font-size: 10px;
    }
    padding: 6px 0px;
  }

  .rating {
    height: 20px;
    margin-bottom: 16px;
  }
}
.backhome {
  font-family: Prompt;
  font-size: 12px;
  background-color: #f2c94c !important;
  border: 1px solid #f2c94c !important;
  color: black !important;
}

.coverImage {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;

  img {
    cursor: pointer;
  }

  img:hover {
    opacity: 0.8;
  }

  .ant-modal-confirm .ant-modal-confirm-btns button + button {
    background-color: red !important;
  }

  .isWishlistClass,
  .isLibClass-1,
  .isLibClass-2:hover {
    bottom: 10px;
    right: 8px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    width: 26px;
    height: 26px;
    -webkit-filter: grayscale(0%) !important;
    filter: grayscale(0%) !important;
  }
  .isWishlistClass:hover {
    opacity: 0.8;
  }
  .isLibClass-1:hover,
  .isLibClass-2 {
    position: absolute;
    top: 20px;
    left: 12px;
    width: 26px;
    height: 24px;
    -webkit-filter: grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }

  .tagList {
    cursor: default;
    @media only screen and (max-width: 441px) {
      top: -6px;
      right: 3px;
    }

    top: 0px;
    right: 3px;
    width: 30px;
    position: absolute;

    .tag {
      margin-top: -3px;
      text-align: center;
      position: relative;
      color: white;
      min-height: 40px;

      .imageTag {
      }

      .tagTitle,
      .tagTitle-discount {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        line-height: 1.1;
        font-size: 10px;
        width: 30px;

        .red {
          color: #ff0000;
          margin-bottom: 0px;
        }

        span {
          color: #ff0000;
          font-weight: bold;
        }
      }
    }

    .offset {
      margin-top: -15px;
    }
  }
}

img {
  width: 100%;
  height: 100%;
}

.collection-home {
  overflow-x: auto;
  overflow-y: hidden;
  display: -webkit-box;
}

.collection,
.collection-home {
  clear: both;
  .empty {
    text-align: center;
    font-family: Bai Jamjuree;
    font-size: 20px;
  }
}

.container {
  position: relative;
}

div.menuBanner {
  height: 180px;
  background-color: darkcyan;
  box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.2);
}

.card-banner-collection {
  margin: 48px 0px;
}

.cover-container .discount,
.cover-container-topic .discount,
.cover-container-related .discount {
  width: 45px;
  height: 25px;
  border-radius: 2px;
  background-color: #d0021b;
  color: white;
  text-align: center;
  font-weight: 500;
  position: absolute;
  right: 0px;
  top: 8px;
}

.cover-container .filetype,
.cover-container-topic .filetype,
.cover-container-related .filetype {
  cursor: default;
  width: 43px;
  height: 17px;
  font-size: 12px;
  background-color: #009f6fb2;
  color: white;
  text-align: center;
  font-weight: 1000;
  position: absolute;
  top: 0px;
  left: 4px;
}

.cover-container .pdf,
.cover-container-topic .pdf,
.cover-container-related .pdf {
  opacity: 0.95;
  background-color: #ff0000;
}

.cover-container .epub,
.cover-container-topic .epub,
.cover-container-related .epub {
  opacity: 0.95;
  background-color: #009f6e;
}

div.ebook-view .iconList {
  position: absolute;
  bottom: 0;
  right: 0;
}

div.ebook-view .ant-btn-circle {
  margin-right: 4px;
  margin-bottom: 4px;
}

.ebookViewLoader {
  display: block;
  position: relative;
  width: 100%;
  height: 280px;
}

.ebookViewLoader-full {
  display: block;
  position: relative;
  width: 100%;
  height: 480px;
}

.ebookViewLoader-home {
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: 8px;
  width: 151px;
  height: 282px;
  @media only screen and (max-width: 420px) {
    width: 116px;
    height: 228px;
  }

  display: block;
  position: relative;
}

.login-container,
.forget-password-box {
  font-family: Prompt;
  width: 100%;
  text-align: center;
  padding: 0px 16px;
  max-width: 400px;
  min-width: 300px;
  margin: auto;

  h1 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0px;
  }

  h3 {
    font-size: 14px;
    text-align: center;
    color: #4f4f4f;
  }

  input {
    height: 32px;
  }

  label {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
  }

  .login-form-button {
    width: 100% !important;
    height: 32px;
    background-color: #189aeb;
    border: none;
  }

  .login-form-button:hover {
    width: 100% !important;
    height: 32px;
    background-color: #189aeb;
    border: none;
  }

  .login-form-button:active {
    transform: scale(0.95);
  }

  .register {
    color: #189aeb !important;
  }
}

.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  background-color: #d9d9d9 !important;
}

.ant-upload-list-item {
  display: none;
}

.ant-upload-list-item {
  display: none;
}

.policy-content .content {
  white-space: inherit;
}

.policy-content {
  .title {
    text-align: center;
    font-family: Prompt;
    font-size: 20px;
    color: black;
  }
  .content,
  .content-head {
    padding: 4px 0px !important;
    font-family: Bai Jamjuree;
    font-size: 12px;
    text-align: left;
    color: black;
  }
  .content-head {
    font-size: 14px !important;
    padding-top: 16px !important;
    margin-bottom: 0;
  }
  .imgSuccessRegister {
    text-align: center;
    .okButton {
      width: 102px;
      height: 32px;
      border-radius: 2px;
      background-color: #189aeb;
      font-family: Prompt;
      font-size: 14px;
      color: white;
      border: none;
    }
  }
}

.register-creator-container-isAuthor {
  width: 100%;
  text-align: center;
  max-width: 838px;
  margin: auto;
  margin-top: 28px;
  .isAuthor {
    font-size: 30px;
    @media (max-width: 600px) {
      font-size: 26px;
    }
    @media (max-width: 530px) {
      font-size: 22px;
    }
    @media (max-width: 455px) {
      font-size: 18px;
    }
    @media (max-width: 385px) {
      font-size: 16px;
    }
    @media (max-width: 350px) {
      font-size: 14px;
    }
    font-family: Prompt;
    color: black;
  }
}

.register-creator-container {
  font-family: Prompt;
  min-height: 429px;
  width: 100%;
  text-align: center;
  padding: 8px;
  max-width: 472px;
  @media (max-width: 325px) {
    min-width: 10px;
  }
  min-width: 325px;
  margin: auto;

  h1 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  label,
  p {
    font-size: 16px !important;
    color: black !important;
  }

  .isAuthor {
    font-size: 30px !important;
  }

  .pOrc {
    width: 111px;
    height: 32px;
    background-color: transparent;
    color: #bdbdbd;
    border: 1px solid #bdbdbd;
  }

  .pOrc-active {
    width: 111px;
    height: 32px;
    background-color: transparent;
    color: #189aeb;
    border: 1px solid #189aeb;
  }

  .accountInfo {
    text-align: left;

    h4 {
      font-family: Prompt;
      font-size: 16px;
      color: black;
      margin-bottom: 0px;
    }

    .displayname {
      margin-bottom: 0px !important;
    }

    .login {
      color: #189aeb !important;
    }

    button {
      width: 100% !important;
      height: 32px;
      background-color: #189aeb;
      border: none;
      margin-bottom: 16px;
    }
  }
  .policycheckbox {
    margin-top: 4px !important;
    margin-bottom: 24px !important;
    span {
      color: #4f4f4f !important;
      font-size: 14px !important;
      font-weight: 300 !important;
    }
  }
  .personalInfo,
  .companyInfo {
    text-align: left;
    .ant-radio-checked .ant-radio-inner {
      border-color: black;
    }
    .ant-radio .ant-radio-inner {
      border-color: black;
    }
    .ant-radio-inner::after {
      background-color: black;
    }
    .ant-radio-wrapper {
      @media (max-width: 480px) {
        margin-right: 30px;
      }
      @media (max-width: 360px) {
        margin-right: 15px;
      }
      margin-right: 60px;
      span.ant-radio + * {
        color: #bdbdbd;
      }
    }
    .ant-radio-wrapper-checked {
      span.ant-radio + * {
        color: black;
      }
    }
  }
  .paymentInfo {
    text-align: left;
    .sameaddress {
      margin-bottom: 20px !important;
      span {
        color: #4f4f4f !important;
        font-size: 14px !important;
        font-weight: 300 !important;
      }
    }
  }
  .prevbtn {
    background-color: white;
    width: 102px;
    height: 32px;
    border-radius: 2px;
    border: solid 1px #189aeb;
    color: #189aeb;
    font-family: Prompt;
    font-size: 14px;
  }

  .prevbtn:hover {
    background-color: white;
    width: 102px;
    height: 32px;
    border-radius: 2px;
    border: solid 1px #189aeb;
    color: #189aeb;
    font-family: Prompt;
    font-size: 14px;
  }

  .prevbtn:active {
    transform: scale(0.95);
  }

  .nextbtn {
    width: 102px;
    height: 32px;
    background-color: #189aeb;
    color: white !important;
    font-family: Prompt;
    font-size: 14px;
    border: none !important;
  }

  .nextbtn:active {
    transform: scale(0.95);
  }
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #189aeb !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #189aeb !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: black !important;
}

.ant-steps-horizontal.ant-steps-label-horizontal
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  width: 0px !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: transparent !important;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  // background-color: white !important;
  display: block !important;
}

.ant-steps-small .ant-steps-item-tail {
  top: 12px !important;
}

.ant-steps-item-tail {
  position: absolute;
  top: 12px !important;
  left: 130px !important;
  width: 100px !important;
  padding: 0 0px;
  z-index: 1000 !important;
}

@media (max-width: 320px) {
  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    width: 0px !important;
  }
}

.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  display: none !important;
}

.ant-steps-small .ant-steps-item-icon {
  width: 26px;
  height: 26px;
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-title {
  font-size: 16px !important;
  font-weight: 300 !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: black !important;
  border-color: black !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: black !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25) !important;
}

.register-container {
  font-family: Prompt;
  height: auto;
  width: 100%;
  text-align: center;
  padding: 16px;
  max-width: 400px;
  min-width: 300px;
  margin: auto;

  .register-channel-container {
    width: 226px;
    margin: 16px auto 0px auto;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    color: #4a4a4a;
    max-height: auto;
  }

  .register-form {
    margin-top: 16px !important;
    h4 {
      font-family: Prompt;
      font-size: 14px;
      font-weight: 300;
      color: black;
      text-align: left;
      margin-bottom: 2px;
    }
    .policy {
      text-align: center;
      color: black;
      font-size: 12px;
      margin-bottom: 8px !important;
    }
  }

  .login {
    color: #189aeb !important;
  }

  h1 {
    font-size: 20px;
  }

  .register-form {
    width: 100%;
  }

  .register-form-button {
    width: 100% !important;
    height: 32px;
    background-color: #189aeb;
    border: none;
    margin-bottom: 16px;
  }

  .register-form-button:hover {
    width: 100% !important;
    height: 32px;
    background-color: #189aeb;
    border: none;
  }

  .register-form-button:active {
    transform: scale(0.95);
  }
}

.login-channel-container,
.forget-password-box-container {
  width: 220px;
  margin: 16px auto;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  max-height: auto;

  h3 {
    font-family: Prompt;
    font-size: 12px;
    font-weight: 300;
    color: #4f4f4f;
    text-align: center;
  }
}

.login-form {
  margin-top: 16px !important;
  h4 {
    font-family: Prompt;
    font-size: 14px;
    font-weight: 300;
    color: black;
    text-align: left;
    margin-bottom: 2px;
  }
}

.loginIconSet {
  display: block;
  width: 100%;

  button {
    font-size: 14px;
    color: white;
    background-repeat: no-repeat;
    height: 32px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 16px;
    padding-left: 50px;
    text-align: left;
    font-family: Prompt;
  }

  .login-email {
    background-image: url("/images/logos/hytexts.svg");
    background-size: 15px 25px;
    background-position: 8px 2px;
    background-color: #189aeb;
    border: solid 1px transparent;
  }

  .login-facebook {
    background-image: url("/images/logos/facebook.png");
    background-size: 28px 28px;
    background-position: 3px 0px;
    background-color: #1877f2;
    border: solid 1px transparent;
  }
  .login-line {
    background-image: url("/images/logos/line.png");
    background-size: 32px 32px;
    background-position: 0px -1px;
    background-color: #00c300;
    border: solid 1px transparent;
  }
  .login-google {
    background-image: url("/images/logos/google.png");
    background-size: 22px 22px;
    background-position: 6px 4px;
    background-color: #ffffff;
    color: black;
    border: solid 1px #000000;
  }
  .login-qrcode {
    background-image: url("/images/logos/qrcode.svg");
    background-size: 22px 22px;
    background-position: 8px 4px;
    background-color: black;
    border: solid 1px transparent;
  }
  .login-qrcode-top {
    width: 153px;
    height: 29px;
    font-size: 10px;
    padding: 0px;
    text-align: center;
    margin-bottom: 0px;
    background-image: url("/images/logos/icons-x.svg");
    background-position: 97% 25%;
    background-color: black;
    border: solid 1px transparent;
    border-radius: 0px;
  }
}

.login-qrcode-bottom {
  width: 153px;
  height: 200px;
  margin: 0px auto;
  background-color: #1d1d1d;
}

.noaccountyet {
  color: black;
  font-size: 12px;
}

.apps-icon {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 18px;
  img {
    height: 40px;
    width: auto;
  }
}

.plays-icon {
  display: inline-block;
  margin-bottom: 18px;
  img {
    height: 40px;
    width: auto;
  }
}

img {
  width: 100%;
  height: auto;
}

p.appstore {
  margin-top: 48px;
  margin-bottom: 8px;
  color: black;
}

.login-form-forgot {
  float: right;
  color: #bdbdbd;
  font-size: 12px;
  padding-top: 3px;
  margin-bottom: 16px;
}

.password-login {
  margin-bottom: 0px !important;
}

.modal-content {
  font-family: Prompt, sans-serif !important;
}

.modal-content p {
  margin-bottom: 0.5em;
}

.modal-content p {
  margin-bottom: 0.5em;
}

.modal-content h1 {
  font-size: 20px;
  margin-bottom: 0px;
}

.modal-content h3 {
  color: #4f4f4f;
  font-size: 14px;
}

.center {
  text-align: center;
}

.modal-content .qrCode {
  width: 220px;
  height: 220px;
  margin: auto;
}

.marginb16 {
  margin-bottom: 16px !important;
}

.sweet-loading {
  background-color: rgb(255, 255, 255);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
}

@media only screen and (max-width: 1000px) {
  .Badge-list {
    justify-content: normal !important;
  }
}

.footer {
  min-height: $footer-height;
}

.total-price-bottom {
  width: 100%;
  padding: 32px 0 64px 0px;
  text-align: center;
  z-index: 100;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
  position: fixed;
  bottom: 0;

  p.price-label {
    font-family: Prompt;
    font-size: 20px;
    color: black;
  }

  .price-total {
    font-family: Bai Jamjuree !important;
    font-weight: 600;
  }

  .price-total:hover {
    font-weight: 600;
  }

  .free-book,
  .price-total {
    font-family: Prompt;
    margin-bottom: 16px;
    font-size: 26px;
    border: none;
    color: white;
    width: 220px;
    height: 48px;
    border-radius: 41px;
    background-color: #189aeb;
  }
  .free-book:hover,
  .price-total:hover {
    border: solid 1px #189aeb;
    color: #189aeb;
    background-color: white;
    transition: all 0.3s ease-in;
  }
}

.cart-container {
  max-width: 810px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: auto;
  @media only screen and (max-width: 375px) {
    padding: 0 0 0 0;
  }
  padding: 16px 16px 16px 16px;

  .titleCart {
    text-align: center;
    color: black;
    font-family: Prompt;
    font-size: 26px;
    font-weight: 500;
    padding-bottom: 16px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 16px;
    .item-num {
      line-height: 32px;
      font-weight: 500;
      font-size: 16px;
    }
  }

  p.header {
    font-size: 14px;
    font-family: Prompt;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    border-top: 1px solid #dbdbdb;
  }

  .cartItem-container {
    min-height: 325px;
    .emptycart {
      font-family: Prompt;
      font-size: 16px;
      text-align: center;
      transform: translateY(166px);
    }
  }

  .total-price {
    @media only screen and (max-width: 900px) {
      margin: 32px 0 0px 0px;
    }
    margin: 32px 0 64px 0px;
    text-align: center;

    p.price-label {
      font-family: Prompt;
      font-size: 20px;
      color: black;
    }

    .price-total {
      font-family: Bai Jamjuree;
      margin-bottom: 16px;
      font-size: 26px;
      border: none;
      font-weight: 600;
      color: white;
      width: 220px;
      height: 48px;
      border-radius: 41px;
      background-color: #189aeb;
      transition: all 0.3s ease-in;
    }
    .price-total:hover {
      border: solid 1px #189aeb;
      color: #189aeb;
      background-color: white;
      transition: all 0.3s ease-in;
    }

    .free-book {
      font-family: Prompt;
      margin-bottom: 16px;
      font-size: 26px;
      border: none;
      color: white;
      width: 220px;
      height: 48px;
      border-radius: 41px;
      background-color: #189aeb;
      transition: all 0.3s ease-in;
    }
    .free-book:hover {
      border: solid 1px #189aeb;
      color: #189aeb;
      background-color: white;
      transition: all 0.3s ease-in;
    }
  }

  .continue-btn {
    font-family: Prompt;
    font-size: 16px;
    border: none;
    color: #189aeb;
    background-color: transparent;
  }
  .continue-btn:hover {
    text-decoration: underline;
  }

  .cart-bottom-label {
    @media only screen and (max-width: 576px) {
      font-size: 12px;
      text-align: left;
    }
    margin-bottom: 8px;
    font-family: Bai Jamjuree;
    font-weight: 600;
    font-size: 16px;
  }
  .cart-bottom-left {
    @media only screen and (max-width: 576px) {
      font-size: 12px;
    }
    text-align: left;
    margin-bottom: 8px;
    font-family: Bai Jamjuree;
    font-weight: 600;
    font-size: 16px;
  }
  .cart-bottom-left-red {
    @media only screen and (max-width: 576px) {
      font-size: 12px;
    }
    color: #a20006;
    text-align: left;
    margin-bottom: 8px;
    font-family: Bai Jamjuree;
    font-weight: 600;
    font-size: 16px;
  }
}

.empty-content,
.ant-empty {
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.cart-item,
.cart-item-delete {
  font-family: Bai Jamjuree;
  margin-bottom: 16px;
  border-bottom: 1px solid #dbdbdb;

  .cover {
    margin-bottom: 16px;
  }

  .medata {
    padding: 0px 16px;

    h2.title {
      @media only screen and (max-width: 375px) {
        font-size: 14px;
      }
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 16px;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h2.title:hover {
      text-decoration: underline;
    }

    h3.author {
      margin-bottom: 2px;
    }

    h3.author,
    h3.publisher {
      @media only screen and (max-width: 375px) {
        font-size: 12px;
      }
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 300;
      color: #4f4f4f;
    }

    h3.author:hover,
    h3.publisher:hover {
      text-decoration: underline;
    }
  }

  .price {
    @media only screen and (max-width: 375px) {
      font-size: 14px;
    }
    font-family: Bai Jamjuree;
    font-weight: 600;
    font-size: 16px;
  }

  .remove {
    color: red !important;
  }
}

.cart-item-delete {
  clear: both;
  animation: deleteRow 500ms ease-in 0s 1 both;
}

.cart-item-was-delete {
  display: none !important;
}

@keyframes deleteRow {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.cartItems-enter {
  opacity: 0.01;
}

.cartItems-enter.cartItems-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.cartItems-leave {
  opacity: 1;
}

.cartItems-leave.cartItems-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.selectEntitle {
  margin: 16px;
  text-align: left;
}

.divider {
  margin: 16px 0px !important;
}

.payment-container {
  font-family: Prompt;
  color: black;
  display: block;
  position: relative;
  max-width: 1050px;
  border-radius: 2px;
  background-color: #ffffff;
  margin: auto;
  @media only screen and (max-width: 555px) {
    padding: 16px 0px 16px 0px;
  }
  @media only screen and (max-width: 375px) {
    padding: 0 0 0 0;
  }
  padding: 16px 16px 16px 16px;

  h1 {
    text-align: center;
    font-size: 26px;
    color: black;
  }

  .coltitle {
    color: black;
    margin-bottom: 8px;
    font-size: 20px;
  }

  .arrow:hover {
    cursor: pointer;
  }

  .inputDisabled {
    @media only screen and (max-width: 420px) {
      width: 100%;
    }
    font-family: Bai Jamjuree;
    margin-bottom: 16px;
    font-size: 26px;
    color: #4f4f4f !important;
    background-color: white !important;
    width: 338px;
    height: 48px;
    text-align: center;
    font-weight: 600;
  }

  .confirm {
    text-align: center;
    margin-top: 44px;
    .confirmBtn {
      @media only screen and (max-width: 420px) {
        width: 100%;
      }
      font-family: Prompt;
      margin-bottom: 16px;
      font-size: 20px;
      border: none;
      color: white;
      width: 150px;
      height: 48px;
      border-radius: 4px;
      background-color: #189aeb;
      transition: all 0.3s ease-in;
    }
    .confirmBtn:hover {
      border: solid 1px #189aeb;
      color: #189aeb;
      background-color: white;
      transition: all 0.3s ease-in;
    }
  }

  .policy {
    font-size: 12px;
    font-weight: 300;
    text-decoration: underline;
  }

  .policy:hover {
    cursor: pointer;
  }

  .shoppingcart {
    @media only screen and (max-width: 991px) {
      height: auto;
      margin: 8px 0 16px 0;
    }
    margin: 8px 0 32px 0;
    height: 200px;
    width: 100%;
    overflow: auto;
  }

  .ant-btn-disabled,
  .ant-btn.disabled,
  .ant-btn[disabled],
  .ant-btn-disabled:hover,
  .ant-btn.disabled:hover,
  .ant-btn[disabled]:hover,
  .ant-btn-disabled:focus,
  .ant-btn.disabled:focus,
  .ant-btn[disabled]:focus,
  .ant-btn-disabled:active,
  .ant-btn.disabled:active,
  .ant-btn[disabled]:active,
  .ant-btn-disabled.active,
  .ant-btn.disabled.active,
  .ant-btn[disabled].active {
    background-color: #bdbdbd;
    color: #4f4f4f;
    border-color: transparent;
  }

  @media only screen and (max-width: 350px) {
  }

  .title,
  .price {
    @media only screen and (max-width: 350px) {
      font-size: 14px;
    }
    font-family: Bai Jamjuree;
    font-size: 16px;
    font-weight: 600;
  }

  .title {
    margin-bottom: 2px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .author {
    margin-bottom: 0px;
    @media only screen and (max-width: 350px) {
      font-size: 12px;
    }
    font-family: Bai Jamjuree;
    font-size: 14px;
    color: #4f4f4f;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .toplabel {
    margin: 8px 0;
    font-family: Prompt;
    font-size: 16px;
    color: black;
  }

  .bottomlabel {
    font-family: Prompt;
    font-size: 16px;
    color: #4f4f4f;
  }

  .price {
    color: #4f4f4f;
  }

  .price-label {
    font-family: Prompt;
    font-size: 20px;
    color: black;
    margin-bottom: 16px;
  }

  .price-total {
    font-family: Bai Jamjuree;
    margin-bottom: 16px;
    border: none;
    font-size: 26px;
    font-weight: 600;
    color: white;
    @media only screen and (max-width: 420px) {
      width: 100%;
    }
    width: 338px;
    height: 48px;
    border-radius: 41px;
    background-color: #189aeb;
    transition: all 0.3s ease-in;
  }
  .price-total:hover {
    border: solid 1px #189aeb;
    color: #189aeb;
    background-color: white;
    transition: all 0.3s ease-in;
  }

  .continue-btn {
    font-family: Prompt;
    font-size: 16px;
    border: none;
    color: #189aeb;
    background-color: transparent;
  }
  .continue-btn:hover {
    text-decoration: underline;
  }

  // .PayPal-active .paymentChannel-active {
  //   animation: expandppDiv 300ms linear 0s 1 both;

  //   @keyframes expandppDiv {
  //     0% {
  //       padding-bottom: 4px;
  //       height: 58px;
  //     }

  //     100% {
  //       padding-bottom: 1%;
  //       height: 142px;
  //     }
  //   }
  // }

  // .PayPal-inactive .paymentChannel-inactive {
  //   animation: minimizeppDiv 300ms linear 0s 1 both;

  //   @keyframes minimizeppDiv {
  //     0% {
  //       padding-bottom: 1%;
  //       height: 142px;
  //     }

  //     100% {
  //       padding-bottom: 4px;
  //       height: 58px;
  //     }
  //   }
  // }

  .paymentChannel,
  .paymentChannel-active,
  .paymentChannel-inactive {
    font-size: 16px;
    min-height: 55px;
    @media only screen and (max-width: 390px) {
      min-height: 65px;
    }
    @media only screen and (max-width: 370px) {
      min-height: 75px;
    }
    @media only screen and (max-width: 320px) {
      min-height: 96px;
    }
    margin-bottom: 16px;
    width: 100%;
    background-color: white !important;
    padding: 8px 16px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
    border: solid 1px #bdbdbd;

    h2 {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      margin: auto;
    }

    p {
      text-align: left;
      font-size: 12px;
    }

    img {
      @media only screen and (max-width: 415px) {
        transform: scale(0.9);
      }
      @media only screen and (max-width: 395px) {
        transform: scale(0.85);
      }
      @media only screen and (max-width: 385px) {
        transform: scale(0.8);
      }
      @media only screen and (max-width: 375px) {
        transform: scale(0.75);
      }
      @media only screen and (max-width: 375px) {
        transform: scale(0.7);
      }
      transition: all 0.3s ease-in-out;
    }

    img:hover {
      @media only screen and (max-width: 415px) {
        transform: scale(0.9);
      }
      @media only screen and (max-width: 395px) {
        transform: scale(0.85);
      }
      @media only screen and (max-width: 385px) {
        transform: scale(0.8);
      }
      @media only screen and (max-width: 375px) {
        transform: scale(0.75);
      }
      @media only screen and (max-width: 375px) {
        transform: scale(0.7);
      }
      transform: scale(1.05);
    }

    .CC {
      width: 161px;
      height: 31px;
    }
    .PP {
      width: 161px;
      height: 37px;
    }
    .TF {
      width: 44px;
      height: 44px;
    }
    .HC {
      width: 145px;
      height: 41px;
    }
    .LP {
      width: 161px;
      height: 29px;
    }
  }

  .PayPal-active {
    .paymentChannel-active {
      @media only screen and (max-width: 390px) {
        min-height: 155px;
      }
      @media only screen and (max-width: 370px) {
        min-height: 165px;
      }
      @media only screen and (max-width: 320px) {
        min-height: 180px;
      }
    }
  }

  .paymentChannel-active {
    border: solid 2px #189aeb !important;
  }

  .creditcard-active-below {
    animation: addDiv 400ms ease-in 0s 1 both;

    @keyframes addDiv {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .paymentChannel-inactive {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    box-shadow: none !important;
  }

  .paymentChannel-inactive:disabled {
    cursor: not-allowed;
  }

  .buyCoinBtn,
  .buyCoinBtn-active,
  .buyCoinBtn-inactive {
    @media only screen and (max-width: 900px) {
      height: 149px;
    }
    height: 176px;
    width: 100%;
    background-color: #fffced;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
    border: none !important;
    transition: all 0.3s ease-in-out;
    .creditAmount {
      @media only screen and (max-width: 900px) {
        transform: translateY(-2px);
      }
      margin-bottom: 0px;
      color: #ffc000;
      text-shadow: -1px 0 #884e00, 0 1px #884e00, 1px 0 #884e00, 0 -1px #884e00;
      font-family: Prompt;
      font-weight: 900;
    }
    .coinpic {
      z-index: 1;
      .thbAmount {
        margin-bottom: 0px;
        color: black;
        font-family: Prompt;
        @media only screen and (max-width: 900px) {
          transform: translateY(-2px);
        }
        font-weight: 900;
      }
    }
    .bonusAmount {
      position: absolute;
      @media only screen and (max-width: 900px) {
        transform: translate(44px, -134px);
        width: 40px;
        height: 40px;
        font-size: 12px;
      }
      transform: translate(80px, -160px);
      width: 51px;
      height: 51px;
      font-size: 16px;
      border-radius: 50%;
      background-color: #e4343a;
      color: white;
      font-family: Prompt;
      font-weight: 900;
    }
  }
  .buyCoinBtn-inactive {
    -webkit-filter: grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }
  .total-price {
    @media only screen and (max-width: 900px) {
      margin: 32px 0 0px 0px;
    }
    color: rgba(0, 0, 0, 0.65);
    margin: 32px 0 64px 0px;
    text-align: center;

    p.price-label {
      font-family: Prompt;
      font-size: 20px;
      color: black;
    }

    .price-total {
      font-family: Bai Jamjuree;
      margin-bottom: 16px;
      font-size: 26px;
      border: none;
      font-weight: 600;
      color: white;
      width: 220px;
      height: 48px;
      border-radius: 41px;
      background-color: #189aeb;
      transition: all 0.3s ease-in;
    }
    .price-total:hover {
      border: solid 1px #189aeb;
      color: #189aeb;
      background-color: white;
      transition: all 0.3s ease-in;
    }

    .free-book {
      font-family: Prompt;
      margin-bottom: 16px;
      font-size: 26px;
      border: none;
      color: white;
      width: 220px;
      height: 48px;
      border-radius: 41px;
      background-color: #189aeb;
      transition: all 0.3s ease-in;
    }
    .free-book:hover {
      border: solid 1px #189aeb;
      color: #189aeb;
      background-color: white;
      transition: all 0.3s ease-in;
    }
  }

  .continue-btn {
    font-family: Prompt;
    font-size: 16px;
    border: none;
    color: #189aeb;
    background-color: transparent;
  }
  .continue-btn:hover {
    text-decoration: underline;
  }

  .cart-bottom-label {
    @media only screen and (max-width: 576px) {
      font-size: 12px;
      text-align: left;
    }
    margin-bottom: 8px;
    font-family: Bai Jamjuree;
    font-weight: 600;
    font-size: 16px;
  }
  .cart-bottom-left {
    @media only screen and (max-width: 576px) {
      font-size: 12px;
    }
    text-align: left;
    margin-bottom: 8px;
    font-family: Bai Jamjuree;
    font-weight: 600;
    font-size: 16px;
  }
  .cart-bottom-left-red {
    @media only screen and (max-width: 576px) {
      font-size: 12px;
    }
    color: #a20006;
    text-align: left;
    margin-bottom: 8px;
    font-family: Bai Jamjuree;
    font-weight: 600;
    font-size: 16px;
  }
}

.notEnoughCoin {
  .title,
  .subtitle {
    text-align: center;
    margin-bottom: 8px;
    font-family: Prompt;
    font-size: 20px;
    color: black;
  }
  .subtitle {
    margin-bottom: 16px;
    font-size: 16px;
  }
  .imgAndBtn {
    text-align: center;
    .cancelButton {
      width: 102px;
      height: 32px;
      border-radius: 2px;
      background-color: white;
      font-family: Prompt;
      font-size: 14px;
      color: #189aeb;
      border: 1px solid #189aeb;
      margin-right: 16px;
    }
    .okButton {
      width: 102px;
      height: 32px;
      border-radius: 2px;
      background-color: #189aeb;
      font-family: Prompt;
      font-size: 14px;
      color: white;
      border: none;
    }
  }
}

.textBadge-list {
  margin: 16px 0px 32px 0px;
  @media only screen and (max-width: 814px) {
    margin: 16px 0px;
  }
}

.textBadge-list-top {
  @media only screen and (max-width: 814px) {
    margin: 0px 0px 24px 0px;
  }
  margin: 0px 0px 32px 0px;
}

.textBadge-list,
.textBadge-list-top {
  height: 100%;
  font-family: Prompt;
  font-size: 20px;
  @media only screen and (max-width: 814px) {
    font-size: 15px;
  }

  h2 {
    font-size: 20px;
    @media only screen and (max-width: 814px) {
      font-size: 16px;
    }
  }

  .Badge-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &::-webkit-scrollbar {
      display: none;
    }
    padding-top: 4px;
    // white-space: nowrap;
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
    .menu-item {
      // justify-content: center; //added on wed 15
      width: 216px !important;
      height: 80px;
      display: table;
      text-align: center;
      white-space: pre-line;
      background-color: #000000;
      padding: 0px 0px;
      cursor: pointer;
      border: 1px white solid;
      border-radius: 4px;
    }

    .menu-item-top1,
    .menu-item-top2,
    .menu-item-top3,
    .menu-item-top4,
    .menu-item-top5,
    .menu-item-top6 {
      // justify-content: center; //added on wed 15
      image-rendering: -webkit-optimize-contrast;
      height: 100px;
      display: table;
      text-align: center;
      white-space: pre-line;
      padding: 0px 0px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #cfcfcf;
      // box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.35);
    }
    .menu-item-top1 {
      width: 1388px;
    }
    .menu-item-top2 {
      width: 676px;
    }
    .menu-item-top3 {
      width: 444px;
    }
    .menu-item-top4 {
      width: 332px;
    }
    .menu-item-top5 {
      width: 260px;
    }
    .menu-item-top6 {
      width: 216px;
    }

    a {
      display: inline-table;
      vertical-align: middle;
      @media only screen and (max-width: 814px) {
        height: 70px;
      }
      height: 110px;
    }
  }
  .atagStyle {
    margin: 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
    height: 80px;
    @media only screen and (max-width: 814px) {
      height: 60px;
    }
    width: 100%;
    color: white;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  .divTopStyle1,
  .divTopStyle2,
  .divTopStyle3,
  .divTopStyle4,
  .divTopStyle5,
  .divTopStyle6 {
    image-rendering: -webkit-optimize-contrast;
    display: inline-block;
    @media only screen and (max-width: 814px) {
    height: 60px;
    }
    height: 100px;
    // @media only screen and (max-width: 768px) {
    //   height: 60px;
    // }
    // height: 120px;
    text-align: center;
    border-radius: 4px;
    // background-color: black;
    color: black;
    // border: 1px solid #cfcfcf;
    box-shadow: 2px 0px 2px 2px rgba(0, 0, 0, 0.09);
  }

  .divTopStyle1 {
    width: 1388px;
  }
  .divTopStyle2 {
    width: 676px;
  }
  .divTopStyle3 {
    width: 444px;
  }
  .divTopStyle4 {
    @media only screen and (max-width: 768px) {
      width: 170px;
    }
    width: 332px;
  }
  .divTopStyle5 {
    @media only screen and (max-width: 814px) {
      width: 160px;
    }
    width: 260px;
  }
  .divTopStyle6 {
    width: 216px;
  }
  .divStyle {
    width: 100%;
    margin-right: 16px;
    height: 80px;
    @media only screen and (max-width: 1360px) {
      display: inline-table;
      width: 220px;
    }
    @media only screen and (max-width: 814px) {
      width: 160px !important;
      height: 60px;
    }
    text-align: center;
    background-color: black;
    color: white;
  }
  .divdivStyle {
    display: inline-table;
    width: 160px;
    height: 160px;
    text-align: center;
  }
  .scrollMenu {
    white-space: nowrap;
  }
}

.flash-sale {
  @media only screen and (max-width: 435px) {
    div.title {
      transform: scale(0.9) translate(-20px, 3px);
    }
  }
  @media only screen and (max-width: 407px) {
    div.title {
      transform: scale(0.8) translate(-40px, 4px);
    }
  }
  @media only screen and (max-width: 373px) {
    div.title {
      transform: scale(0.7) translate(-58px, 9px);
    }
  }
  div.title {
    height: 0px;
    text-align: left;

    .flash-icon {
      @media only screen and (max-width: 1414px) {
        margin-left: 16px;
      }
      margin-bottom: 0.5em;
      width: 161px;
      height: auto;
      float: left;
    }

    .count-down-timer {
      display: flex;
      align-items: center;
      justify-content: normal;
      transform: translateX(16px) translateY(4px);

      div.daylabel {
        margin-right: 8px;
        color: black;
        font-family: Prompt;
        font-size: 14px;
      }

      div.cout-time {
        min-width: 20px;
        padding: 0px 4px;
        font-family: Prompt;
        font-size: 14px;
        text-align: center;
        color: white;
        z-index: -1;
        background-color: black;
        line-height: 20px;
        margin-right: 8px;
        border-radius: 2px;
        @media only screen and (max-width: 320px) {
          margin-right: 4px;
        }
      }

      div.cout-timee {
        font-family: Prompt;
        font-size: 14px;
        text-align: center;
        color: white;
        z-index: -1;
        background-color: black;
        line-height: 20px;
        margin-right: 6px;
        border-radius: 2px;
        width: 20px;
        @media only screen and (max-width: 320px) {
          margin-right: 4px;
        }
      }
    }
  }
}

.flash-sale-ebook {
  display: flex;
  z-index: 100;
  justify-content: center;
  background-color: #f2c94c;
  height: 56px;
  div.title {
    @media only screen and (max-width: 375px) {
      padding: 0 8px;
    }
    height: 8px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: normal;
    transform: translateY(25px);
    .flash-icon {
      width: 161px;
      @media only screen and (max-width: 375px) {
        margin-right: 0px;
      }
      margin-right: 19px;
      height: auto;
      float: left;
    }
    .discount {
      margin-right: 19px;
      font-weight: 600;
      font-family: Prompt;
      font-size: 30px;
      color: #e4343a;
    }

    .count-down-timer {
      display: flex;
      align-items: center;
      justify-content: normal;
      div.daylabel {
        margin-right: 8px;
        color: black;
        font-family: Prompt;
        font-size: 14px;
      }

      div.cout-time {
        min-width: 20px;
        padding: 0px 4px;
        font-family: Prompt;
        font-size: 14px;
        text-align: center;
        color: white;
        z-index: -1;
        background-color: black;
        line-height: 20px;
        margin-right: 8px;
        border-radius: 2px;
      }

      div.cout-timee {
        font-family: Prompt;
        font-size: 14px;
        text-align: center;
        color: white;
        z-index: -1;
        background-color: black;
        line-height: 20px;
        margin-right: 6px;
        border-radius: 2px;
        width: 20px;
      }
    }
    .btn-flash {
      transform: translateY(-3px);
      .trybtn-flash {
        margin: 0px 16px 0px 83px;
        width: 102px;
        height: 31px;
        border-radius: 24px;
        border: solid 1px black !important;
        background-color: white;
        font-family: Prompt;
        font-size: 12px;
        color: black;
      }
      .pricebtn-flash {
        width: 102px;
        height: 31px;
        border-radius: 24px;
        padding: 0 0 0 0;
        border: none !important;
        background-color: red;
        font-family: Prompt;
        font-size: 13px;
        color: white;
      }
      .pricebtn-flash:hover {
        border: solid 1px red !important;
        background-color: white;
        color: red;
      }
      .addcartbtn-flash {
        width: 122px;
        height: 31px;
        border-radius: 24px;
        padding: 0 0 0 0;
        border: none !important;
        background-color: black;
        font-family: Prompt;
        font-size: 13px;
        color: white;
      }
    }
  }
}

img.nav-logo {
  width: 26px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

img.nav-logo:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}

.marginLeft16 {
  margin-left: 16px;
}

.category-all {
  font-family: Prompt;
  margin: auto;
  h1 {
    text-align: center;
    font-size: 32px;
    margin: 0 0 35px 0;
  }
  .gradient {
    border: none !important;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    // background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  }
  button {
    text-align: left;
    font-size: 20px;
    color: white;
    border: none;
  }
  p {
    font-size: 16px;
    margin: 10px 0 -10px 0;
  }
}

.paymentHistory {
  .prompt26 {
    color: black;
  }
  .contentTypeGroup {
    font-family: Prompt;
  }
  .orderCodeCol {
    h3 {
      text-decoration: underline;
    }
  }
  .orderCodeCol:hover {
    cursor: pointer;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #189aeb !important;
    border: 1px solid #189aeb !important;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper:hover {
    color: #189aeb !important;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:hover {
    color: white !important;
  }
  .inactive:hover {
    color: #189aeb;
  }
}

.hytextsBankAccount,
.paymentSuccess,
.paymentHistory {
  margin: auto;
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
  width: 692px;
  text-align: center;
  h3 {
    color: black;
  }
  .prompt30 {
    font-family: Prompt;
    font-size: 30px;
  }
  .prompt26 {
    font-family: Prompt;
    font-size: 26px;
  }
  .prompt20 {
    font-family: Prompt;
    font-size: 20px;
  }
  .prompt18 {
    font-family: Prompt;
    font-size: 18px;
  }
  .prompt16 {
    font-family: Prompt;
    font-size: 16px;
  }

  .BaiJamJuree16 {
    font-family: Bai JamJuree;
    font-size: 16px;
  }
  .BaiJamJuree14 {
    font-family: Bai JamJuree;
    font-size: 14px;
  }
  .BaiJamJuree12 {
    font-family: Bai JamJuree;
    font-size: 12px;
  }

  .margin8_0 {
    margin: 8px 0px;
  }

  .priceBox {
    text-align: center;
    margin: auto;
    width: 338px;
    @media only screen and (max-width: 364px) {
      width: 100%;
    }
    height: 82px;
    border-radius: 4px;
    border: solid 1px #ff0000;
    background-color: #f8f8f8;
  }

  .orderNumberBox {
    text-align: left;
    margin: 45px auto 0px auto;
    width: 689px;
    @media only screen and (max-width: 689px) {
      width: 100%;
    }
    height: 40px;
  }

  .paymentDetailBox {
    text-align: center;
    margin: 23px auto;
    height: auto;
    .bankBox {
      text-align: left;
      margin: 23px auto;
      @media only screen and (max-width: 360px) {
        width: 100%;
      }
      width: 330px;
    }
    .promptpayBox {
      text-align: center;
      margin: 2px auto;
      @media only screen and (max-width: 300px) {
        width: 100%;
      }
      width: 300px;
    }
  }

  .paymentChannelBox {
    @media only screen and (max-width: 450px) {
      width: 100%;
    }
    text-align: center;
    margin: 32px auto;
    width: 420px;
    height: auto;
    .channels {
      margin: 16px auto;
      text-align: left;
    }
  }

  .contactInfo {
    @media only screen and (max-width: 626px) {
      width: 100%;
    }
    color: black;
    text-align: left;
    width: 626px;
    margin: auto;
  }

  .toHome {
    width: 162px;
    height: 32px;
    border-radius: 2px;
    background-color: white;
    padding: 0 0 0 0;
    border: 1px solid #189aeb !important;
    font-family: Prompt;
    font-size: 16px;
    color: #189aeb;
  }
  .toHome:hover {
    background-color: #189aeb;
    border: none !important;
    color: white;
    transition: all 0.3s ease-in;
  }

  .aftercart {
    @media only screen and (max-width: 775px) {
      width: 100%;
    }
    color: black;
    margin: 16px 0 16px 0;
    width: 692px;
    overflow: auto;
    .title,
    .price,
    .coinReceive {
      font-weight: 600;
      font-family: Bai Jamjuree;
      font-size: 16px;
    }

    .title {
      margin-bottom: 3px;
    }

    .author {
      margin-bottom: 2px;
    }

    .title,
    .author {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .author,
    .coinBonus {
      font-family: Bai Jamjuree;
      font-size: 14px;
      color: #4f4f4f;
    }

    .title:hover,
    .author:hover {
      text-decoration: underline;
    }
  }
}

.authorpublisherAll {
  p {
    font-family: Prompt;
    font-size: 32px;
    color: black;
    margin-bottom: 0px;
  }
  .authorpublisherBox {
    @media only screen and (max-width: 375px) {
      margin: auto -8px;
      transform: scale(0.9);
    }
    @media only screen and (max-width: 320px) {
      margin: auto -32px;
      transform: scale(0.8);
    }
    width: 338px;
    height: 134px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    .displayname {
      margin: 16px 0 0 8px;
      text-align: left;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Prompt;
      font-size: 16px;
      color: black;
    }
    .displayname:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    .labeltop {
      margin-bottom: 0px;
      font-family: Prompt;
      font-size: 16px;
      color: black;
    }
    .labelunder {
      font-family: Bai Jamjuree;
      font-size: 14px;
      color: #4f4f4f;
    }
  }
}

.faq-link:hover {
  text-decoration: underline;
}

.faq {
  .leftfaq {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    max-width: 470px;
    // position: fixed;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  }
  .title {
    margin-left: 16px;
    margin-bottom: 8px;
    font-family: Prompt;
    font-size: 48px;
    color: #0d232b;
    font-weight: 600;
  }
  .arrow {
    padding: 0px 8px;
  }
  .subjectRow,
  .subjectRow-blue {
    height: 55px;
  }
  .subjectRow:hover,
  .subjectRow-blue:hover {
    height: 55px;
    cursor: pointer;
  }
  .subject {
    margin: 0px;
    font-family: Prompt;
    font-size: 20px;
  }
  .subjectRow {
    background-color: white;
    .subject {
      color: #4f4f4f;
    }
  }
  .subjectRow-blue {
    background-color: #f5f5f5;
    .subject {
      color: #189aeb;
    }
    .prefix {
      height: 55px;
      width: 6px;
      background-color: #189aeb;
    }
  }
  .belowSubjectRow {
    margin-top: 8px;
    margin-bottom: 24px;
  }
  .belowSubject,
  .belowSubject-blue,
  .belowSubSubject,
  .belowSubSubject-blue {
    margin-bottom: 8px;
    font-family: Prompt;
    font-size: 16px;
  }
  .belowSubject,
  .belowSubject-blue {
    margin-left: 59px;
  }
  .belowSubSubject,
  .belowSubSubject-blue {
    margin-left: 79px;
  }
  .belowSubject,
  .belowSubSubject {
    color: #8f8f8f;
  }
  .belowSubject-blue,
  .belowSubSubject-blue {
    color: #189aeb;
  }
  .belowSubject:hover,
  .belowSubject-blue:hover,
  .belowSubSubject:hover,
  .belowSubSubject-blue:hover {
    cursor: pointer;
  }
  .sideSubject {
    @media only screen and (min-width: 901px) {
      margin-left: 16px;
    }
  }
}

.faqMenu {
  .leftfaq {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    max-width: 470px;
    // position: fixed;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  }
  .title {
    margin-left: 16px;
    margin-bottom: 8px;
    font-family: Prompt;
    font-size: 48px;
    color: #0d232b;
    font-weight: 600;
  }
  .subjectRow,
  .subjectRow-blue {
    height: 25px;
  }
  .subjectRow:hover,
  .subjectRow-blue:hover {
    height: 25px;
    cursor: pointer;
  }
  .subject {
    margin: 0px;
    font-family: Prompt;
    font-size: 14px;
  }
  .subjectRow {
    background-color: white;
    .subject {
      color: #4f4f4f;
    }
  }
  .subjectRow-blue {
    // background-color: #f5f5f5;
    .subject {
      color: #189aeb;
    }
    .prefix {
      height: 40px;
      width: 6px;
      background-color: #189aeb;
    }
  }
  .belowSubjectRow {
    margin-top: 4px;
    margin-bottom: -4px;
  }
  .belowSubject,
  .belowSubject-blue,
  .belowSubSubject,
  .belowSubSubject-blue {
    margin-bottom: 8px;
    font-family: Prompt;
    font-size: 16px;
  }
  .belowSubject,
  .belowSubject-blue {
    margin-left: 59px;
  }
  .belowSubSubject,
  .belowSubSubject-blue {
    margin-left: 79px;
  }
  .belowSubject,
  .belowSubSubject {
    color: #8f8f8f;
  }
  .belowSubject-blue,
  .belowSubSubject-blue {
    color: #189aeb;
  }
  .belowSubject:hover,
  .belowSubject-blue:hover,
  .belowSubSubject:hover,
  .belowSubSubject-blue:hover {
    cursor: pointer;
  }
  .sideSubject {
    margin-left: 16px;
  }
}

.close-icon{
  width: 30px;
}