.privacy-page{
    font-family: "Prompt",
    sans-serif;
    .privacy-content{
       max-width: 380px;
       margin: 0 auto;
        @media only screen and (max-width:900px) {
            padding:0px;
        }
    }
    .navigate-box{
        padding:10px ;
        background-color: #FAFAFA;
        color: #189aeb;
        width: fit-content;
        max-width: 220px;
        position: fixed;
        h3{
          color: #189aeb;
        }
        a {
            &:hover {
                text-decoration: underline;
            }
        }
        @media only screen and (max-width:900px) {
            position: relative;
            width: 100%;
            max-width: unset;
            margin-bottom: 16px;
        }
    }

    .PDPA-form{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        .input-section{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            width: 100%;
        }
        .error-detail{  
            color: #f5222d;
            font-size: 12px;
           
        }
        .input-group{
            text-align: start;
            width: 100%;
        }
        .require{
            color: #D7042B
        }
        .PDPA-title{
            color: #189aeb;
            font-weight: 600;
        }
        .PDPA-subTitle{
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
        }
        .PDPA-input{
            background: #fcfcfc;
            border-radius: 12px;
        }
        .ant-checkbox-group{
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            text-align: start;
        }
        .pdpa-file-upload__container{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap:16px;
            .pdpa-file-upload {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            
                .ant-upload-list-item {
                    display: none;
                }
            }
            .file-list{
                width: 100%;
                .file-row {
                    padding: 5px;
                    border-radius: 6px;
                    display: flex;
                    justify-content: space-between;
                    gap:16px;
                    width: 100%;
                    align-items: center;
                    text-align: start;
                    &:hover {
                        background-color: #e6f7ff;
                    }
                    svg{
                        cursor: pointer;
                    }
                    .file-row-name{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                        display: block;
                        white-space: pre;
                    }
                }
              
            }
          
        }
     
        .submit-btn{
            width: 100%;
            height: 48px;
            padding: 0px;
            border-radius: 50px;
            background-color: #189aeb !important;
            border: 1px solid #189aeb !important;
            color: white;
        }
        .pdpa-note{
            padding:10px;
            background-color: #FAFAFA;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: start;
            border-radius: 12px;
        }
    }

    .side {
        padding-left: 250px;
        padding-right: 50px;
        @media only screen and (max-width:900px) {
            padding: 0px;
        }
        img {
            image-rendering: -webkit-optimize-contrast;
        }
        .section-space{
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
        .side-title,
        .side-title-c,
        .side-title-cc,
        .side-title-hytexts,
        .side-title-bold {
            margin-top: 24px;
            font-family: Prompt;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
    
        .side-title {
            font-size: 26px;
            font-weight: 600;
            color: #189aeb;
        }

        .side-title-hytexts{
            color: #189aeb;
            font-size: 24px;
            font-weight: 600;
            @media only screen and (min-width: 901px) {
                margin-left: 43px;
            }
        }
    
        .side-title-c {
            font-size: 26px;
            text-align: center;
            color: #189aeb;
            font-weight: 600;
        }
    
        .side-title-cc {
            margin-top: 60px !important;
            font-size: 26px;
            text-align: center;
        }

        .side-title-bold{
            @media only screen and (min-width: 901px) {
                margin-left: 43px;
            }
            font-size: 16px;
            font-weight: 500;
        }
    
        .side-content,
        .side-content-lessBottom {
            font-family: Bai JamJuree !important;
        }
    
        .side-content {
            img {
                margin-top: 14px;
            }
    
            .imgMobile {
                width: 300px;
                height: auto;
                margin: 8px 16px;
                border: 1px solid black;
            }
    
            @media only screen and (min-width: 901px) {
                margin-left: 43px;
            }
    
            margin-bottom: 64px;
            font-family: Prompt;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #4f4f4f;
        }

        .list-in-content{
            margin: 20px 0px;
            padding-inline: 24px
        }
    
        .side-content-lessBottom {
            img {
                margin-top: 14px;
                border: 1px solid black;
            }
    
            .imgMobile {
                width: 300px;
                height: auto;
                margin: 8px 16px;
            }
    
            @media only screen and (min-width: 901px) {
                margin-left: 43px;
            }
    
            margin-bottom: 8px;
            font-family: Prompt;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #4f4f4f;
            a{
                color: #189aeb;
                text-decoration: underline;
            }
        }
    }
}

.pdpa-success__modal{
    width: 300px;
    font-family: "Prompt";
    img{
        width: 75px;
        margin: 0 auto;
    }
    .anticon-info-circle{
        display: none;
    }
    .ant-modal-confirm-btns{
        display: none;
    }

    .pdpa-success-body__modal{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px;
    }
    .close-icon{
        width: 30px;
    }
}