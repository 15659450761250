.ant-carousel .slick-slide {
  text-align: center;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;

  h3 {
    color: #fff;
  }
}

.center {
}

div.slide {
  @keyframes fadeInSlide {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOutSlide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  .nogradient {
    animation: fadeInSlide 700ms ease-in 0s 1 both;
  }
  .gradient {
    animation: fadeOutSlide 700ms ease-in 0s 1 both;
  }
  max-width: 900px;
}
