.side {
  img {
    image-rendering: -webkit-optimize-contrast;
  }
  .side-title,
  .side-title-c,
  .side-title-cc {
    margin-top: 24px;
    font-family: Prompt;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  .side-title {
    font-size: 20px;
  }
  .side-title-c {
    font-size: 26px;
    text-align: center;
  }
  .side-title-cc {
    margin-top: 60px !important;
    font-size: 26px;
    text-align: center;
  }
  .side-content,
  .side-content-lessBottom {
    font-family: Bai JamJuree !important;
  }
  .side-content {
    img {
      margin-top: 14px;
    }
    .imgMobile {
      width: 300px;
      height: auto;
      margin: 8px 16px;
      border: 1px solid black;
    }
    @media only screen and (min-width: 901px) {
      margin-left: 43px;
    }
    margin-bottom: 64px;
    font-family: Prompt;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #4f4f4f;
  }
  .side-content-lessBottom {
    img {
      margin-top: 14px;
      border: 1px solid black;
    }
    .imgMobile {
      width: 300px;
      height: auto;
      margin: 8px 16px;
    }
    @media only screen and (min-width: 901px) {
      margin-left: 43px;
    }
    margin-bottom: 8px;
    font-family: Prompt;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #4f4f4f;
  }
}

.faq {
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0px;
  }

  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: 27px;
  }
}
